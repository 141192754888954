import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql, Link, useStaticQuery } from 'gatsby';
import DocsLayout from '../components/DocsLayout';
import Grid from '../components/Grid';
import Typography from '../components/Typography';

const localizations = [
  {
    locale: 'nl-NL',
    slug: '/nl/docs/',
  },
];

const Docs = () => {
  const data = useStaticQuery(graphql`
    query DocsListQuery {
      allStrapiDoc(filter: { locale: { eq: "en" } }) {
        edges {
          node {
            title
            strapiId
            slug
            locale
            strapiParent {
              title
              id
              slug
            }
          }
        }
      }
    }
  `);
  const { allStrapiDoc } = data;
  const { edges } = allStrapiDoc;
  const rootDocs = edges.filter(({ node }) => node?.strapiParent === null);
  return (
    <DocsLayout locale="en" localizations={localizations} slug="/docs/">
      <Helmet title="Documentatie | Afosto">
        <html lang="en" />
      </Helmet>
      <Grid container fluid className="py-32">
        <Typography variant="h1" className="mb-12">
          Documentation
        </Typography>
        <Typography variant="body-large" className="mb-80">
          Discover our manuals and examples to use Afosto to it's fullest potential.
        </Typography>
        <Grid row spacing={16}>
          {rootDocs.map(({ node: doc }) => {
            const subDocs = edges.filter(({ node }) => node?.strapiParent && node?.strapiParent?.id === doc?.strapiId);

            return (
              <Grid column xs={12} md={4} key={doc.slug}>
                <Typography
                  variant="h4"
                  component={Link}
                  to={`/${doc.locale !== 'en' ? 'nl/' : ''}${doc.slug.replace(/(^en\/)/gi, '')}`}
                  className="mb-16 d-block"
                >
                  {doc.title}
                </Typography>
                <ul className="list-unstyled my-n4 pb-16">
                  {subDocs.map(({ node: subDoc }) => (
                    <li className="py-4">
                      <Link to={`/${subDoc.locale !== 'en' ? 'nl/' : ''}${subDoc.slug.replace(/(^en\/)/gi, '')}`}>
                        {subDoc?.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </DocsLayout>
  );
};

export default Docs;
